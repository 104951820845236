<template>
  <div>
    <v-btn color="primary" dark @click.stop="showDialog()">
      Create Event
    </v-btn>
    <v-dialog v-model="dialog" persistent max-width="400" scrollable>
      <v-card>
        <v-card-title class="title">Create Event</v-card-title>
        <v-card-text>
          <event-form
            ref="eventForm"
            :key="componentKey"
            :form="form.event"
            :itemId="itemId"
            :allow-multiple-locations="true"
          >
          </event-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog()" color="error">
            Cancel
          </v-btn>
          <v-btn
            class="bg-secondary text-color-white"
            @click="submit()"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import moment from "moment";
import Storage from "@/services/storage.js";
import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";

import { RepositoryFactory } from "@/services/repositories/repositoryFactory";

const eventRepository = RepositoryFactory.get("event");

const EventForm = () => import("../forms/EventForm.vue");

let storage = new Storage();

import { setToUtc } from "@/services/helpers/date";

export default {
  components: {
    EventForm
  },
  data: () => ({
    dialog: false,
    componentKey: 0,
    form: {
      location_ids: []
    },
    itemId: null,
    loading: false,
    eventName: "reloadItems_eventCalendar"
  }),
  methods: {
    async showDialog() {
      await this.setLocationId();
      this.dialog = true;
    },
    setLocationId() {
      let user = storage.get("user");

      // || user.user_type === "admin"
      if (user.user_type === "super_admin") {
        return true;
      }

      this.form = {
        event: {
          location_ids: [storage.get("location_id")]
          // location_id: storage.get("location_id")
        }
      };

      return;
    },
    closeDialog() {
      this.componentKey++;
      this.dialog = false;
    },
    submit() {
      let eventForm = this.$refs.eventForm;

      let valid = eventForm.validate();

      if (valid) {
        let form = eventForm.form;
        form.event_at = setToUtc(`${form.startDate} ${form.startTime}`).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        form.event_end_at = setToUtc(`${form.endDate} ${form.endTime}`).format(
          "YYYY-MM-DD HH:mm:ss"
        );

        // form.event_at = moment(`${form.startDate} ${form.startTime}`)
        //   .utc()
        //   .format("YYYY-MM-DD HH:mm:ss");
        // form.event_end_at = moment(`${form.endDate} ${form.endTime}`)
        //   .utc()
        //   .format("YYYY-MM-DD HH:mm:ss");

        const formData = new FormData();
        Object.keys(form).forEach(key => {
          if (form[key]) {
            formData.set(key, form[key]);
          }
        });
        form.location_ids.forEach((id, index) => {
          formData.append(`location_ids[${index}]`, id);
        });

        eventRepository
          .create(formData)
          .then(response => {
            console.log({ response }, "create");

            this.MIXINS_SHOW_SNACKBAR("success", "Successfully Created.");

            let queryOnThisMonth = response.data.responseData.event_at;

            GLOBAL_EVENT_BUS.$emit(this.eventName, queryOnThisMonth);
          })
          .catch(error => {
            console.log({ error });

            let objectError = error.response.data.errors;

            for (const [key] of Object.entries(objectError)) {
              let errorMessage = objectError[key][0];

              this.MIXINS_SHOW_SNACKBAR("error", errorMessage);

              break;
            }
          })
          .finally(() => {
            this.loading = false;
            this.closeDialog();
          });
      }
    }
  }
};
</script>
